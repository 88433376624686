
const WhatWeDoCard = (props) => {

    return ( 
        // <div className="p-5 rounded-xl text-center">
        //         <div>
        //             {props.icon}
        //         </div>
                
        //         <div className="mb-2">
        //             <p className="text-[#FFC000] text-xl font-medium poppins">{props.title}</p>
        //         </div>
        //         {/* <p className="text-white text-base poppins">{props.desc}</p> */}
        // </div>

        <div className="group hover:bg-[#FFC000] bg-white relative w-full h-full rounded-lg hover:shadow-lg duration-500">
            <div className="h-12 w-12 flex justify-center items-center absolute -top-5 left-3 rounded-full text-white bg-[#FFC000] group-hover:text-[#FFC000] group-hover:bg-white duration-500">
                {props.icon}
            </div>
            <div className="pt-10 pb-6 px-3">
                <p className="text-[#FFC000] text-xl font-medium poppins group-hover:text-white duration-500">{props.title}</p>
                <p className="text-black text-base poppins">{props.desc}</p>
            </div>

        </div>
     );
}
 
export default WhatWeDoCard;

import { useEffect, useState } from "react";
import aboutUs3 from "../../assets/images/about-us-3.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";


const WhoWeAre = () => {
    
    const content = {
        visible: {
            transition: { 
                type: "spring",
                bounce: 0,
                duration: 0.5,
                delay:1,
                delayChildren: 0.5,
                staggerChildren: 0.4 
            } 
        },
        hidden: { transition: {duration:0.2, delay:0.5} }
    };
    const itemVariants = {
        visible: {
          opacity: 1,
          y: 0,
          transition: { type: "spring", stiffness: 150, damping: 50 }
        },
        hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } }
      };

    const [isOpen, setOpen] = useState(false);
    const [ref, inView] = useInView({});

    useEffect(() => {
        if (inView) {
            setOpen(true);
            // control.start("visible");

        }
    }, [inView]);

    return ( 
        <motion.div 
            ref={ref}
            initial={false}
            variants={content} 
            animate={isOpen ? "visible":"hidden"}
            className="">
            <div className="pt-40 pb-20 px-5 lg:px-16 2xl:px-56 bg-cover bg-no-repeat bg-center" style={{ backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),url(${aboutUs3})`}}>
                <div className="grid text-center xl:text-left grid-cols-1 xl:px-0">
                    <div>
                        <motion.p variants={itemVariants} id="title_1" className="text-5xl font-medium text-white poppins mb-5">Who <span className="text-[#FFC000]">we</span> are.</motion.p>
                        <motion.p variants={itemVariants} id="content_1" className="text-white text-2xl md:text-3xl lg:text-4xl text-justify poppins mb-3">GTR Production, startup company dengan spirit generasi muda untuk memajukan industri dan komunitas era digital. Memberikan layanan terbaik, berbasis multimedia, teknologi digital dan trend terkini.</motion.p>
                    </div>
                    {/* <div></div> */}
                </div>

            </div>
            <div className="py-20 px-5 lg:px-16 2xl:px-56">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <motion.div variants={itemVariants} className="content-2">
                        <p className="text-white text-3xl font-bold mb-3 poppins"><span className="text-[#FFC000]">Vision</span>.</p>
                        <p className="text-white text-2xl font-bold mb-2 poppins">Globally Competitive Company Through Innovation & Collaboration</p>
                        <p className="text-white text-lg text-justify poppins">Menjadi perusahaan yang berdaya saing global dengan mengedepankan inovasi dan kolaborasi mewujudkan kesejahteraan dan praktik bisnis yang berkelanjutan.</p>
                    </motion.div>
                    <motion.div variants={itemVariants} className="content-2">
                        <p className="text-white text-3xl font-bold mb-3 poppins"><span className="text-[#FFC000]">Mission</span>.</p>
                        <p className="text-white text-lg text-justify poppins">Menyediakan produk dan jasa yang berkualitas dengan nilai tambah, serta menjalin kemitraan dengan mitra dan stakeholder.</p>
                    </motion.div>
                </div>
            </div>
        </motion.div>
     );
}
 
export default WhoWeAre;

import { useEffect, useState } from "react";
import whatwethink from "../../assets/images/whatwethink.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";


const WhatWeThink = () => {    
    const title = {
        visible: { opacity: 1, transition: { duration: 1} },
        hidden: { opacity:0 }
    };

    const content = {
        visible: {
            transition: { 
                type: "spring",
                bounce: 0,
                duration: 0.5,
                delay:1,
                delayChildren: 0.2,
                staggerChildren: 0.2 
            } 
        },
        hidden: { transition: {duration: 1, delay:0.5} }
    };
    const itemVariants = {
        visible: {
          opacity: 1,
          x: 0,
          transition: { type: "spring"}
        },
        hidden: { opacity: 0, x: -20, transition: { duration: 0.2 } }
      };

    const image = {
        visible: { opacity: 1, transition: {when: "beforeChildren", duration: 2 } },
        hidden: { opacity: 0, }
    };
    const [isOpen, setOpen] = useState(false);
    const [ref, inView] = useInView({});


    useEffect(() => {
        if (inView) {
            setOpen(true);
        }
    }, [inView]);




    return ( 
        <>
            {/* <motion.div ref={ref} variants={content} 
                            initial={false}
                            animate={isOpen ? "visible":"hidden"}
             className="pt-36 pb-10 px-5 lg:px-16 2xl:px-56">
                    <motion.p variants={title} id="title_1" className="text-5xl font-medium text-white poppins mb-10">What <span className="text-[#FFC000]">We</span> Think.</motion.p>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                        <div>
                            <div className="grid grid-cols-1 gap-5">

                                <motion.div variants={itemVariants} className="content-2">
                                    <p className="text-white text-3xl font-medium mb-3 poppins"><span className="text-[#FFC000]">Think Globally Act Locally</span>.</p>
                                    <p className="text-white text-lg text-justify poppins">Kami selalu berupaya untuk berfikir secara global namun tetap menjunjung nilai-nilai lokal.</p>
                                </motion.div>
                                <motion.div variants={itemVariants} className="content-2">
                                    <p className="text-white text-3xl font-medium mb-3 poppins"><span className="text-[#FFC000]">Collaboration and Innovation in Action</span>.</p>
                                    <p className="text-white text-lg text-justify poppins">Kami menyambut baik segala macam bentuk kolaborasi dan inovasi guna mencapai visi yang sama.</p>
                                </motion.div>
                                <motion.div variants={itemVariants} className="content-2">
                                    <p className="text-white text-3xl font-medium mb-3 poppins"><span className="text-[#FFC000]">Unity in Diversity</span>.</p>
                                    <p className="text-white text-lg text-justify poppins">Kami menerima setiap perbedaan dan bersama-bersama menciptakan persatuan guna mewujudkan tujuan bersama.</p>
                                </motion.div>
                            </div>
                        </div>
                        <motion.div variants={image} id="image_1" className="hidden md:block">
                            <img src={whatwethink} className="w-full" alt="" />
                        </motion.div>
                    </div>

                
            </motion.div> */}

            <div className="pt-32 pb-0">
                <div className="relative overflow-hidden">
    
                    <motion.div
                        ref={ref}
                        variants={content}
                        initial="hidden"
                        animate={isOpen ? "visible":"hidden"}
                        className="lg:px-32 xl:px-40 2xl:px-72"
                        >
                            <div className="grid grid-cols-1 lg:grid-cols-2 p-10">
                                <div>
                                    {/* <motion.p className="text-4xl md:text-5xl text-white font-medium poppins mb-5">Digital and <span className="text-[#FFC000]">Creatives</span> Multimedia<span className="text-[#FFC000]">.</span></motion.p> */}
                                    <motion.p variants={title} id="title_1" className="text-5xl font-medium text-white poppins mb-5">What <span className="text-[#FFC000]">We</span> Think.</motion.p>

                                    <motion.div variants={itemVariants} className="content-2">
                                        <p className="text-white text-3xl font-medium mb-3 poppins"><span className="text-[#FFC000]">Think Globally Act Locally</span>.</p>
                                        <p className="text-white text-lg text-justify poppins">Kami selalu berupaya untuk berfikir secara global namun tetap menjunjung nilai-nilai lokal.</p>
                                    </motion.div>
                                    <motion.div variants={itemVariants} className="content-2">
                                        <p className="text-white text-3xl font-medium mb-3 poppins"><span className="text-[#FFC000]">Collaboration and Innovation in Action</span>.</p>
                                        <p className="text-white text-lg text-justify poppins">Kami menyambut baik segala macam bentuk kolaborasi dan inovasi guna mencapai visi yang sama.</p>
                                    </motion.div>
                                    <motion.div variants={itemVariants} className="content-2">
                                        <p className="text-white text-3xl font-medium mb-3 poppins"><span className="text-[#FFC000]">Unity in Diversity</span>.</p>
                                        <p className="text-white text-lg text-justify poppins">Kami menerima setiap perbedaan dan bersama-bersama menciptakan persatuan guna mewujudkan tujuan bersama.</p>
                                    </motion.div>
                                </div>
                            </div>
                    </motion.div>
                    <div className="lg:absolute right-0 top-0 h-[300px] lg:h-full w-full lg:w-[40%]">
                        <div className="block lg:absolute bg-cover bg-no-repeat bg-center lg:top-0 lg:right-0 h-full w-full" style={{backgroundImage: `url('${whatwethink}')`}}></div>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default WhatWeThink;
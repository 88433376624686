import axios from "axios"

export const getOurClients = async () => {
    try {
        const response = await axios.get("/datasources/clients.json");
        return response;
    } catch (error) {
        throw error;
    }
}



export const getPortofolios = async () => {
    try {
        const response = await axios.get("/datasources/portofolios.json");

        return response;
    } catch (error) {
        throw error;
    }
}




export const getOurTeams = async () => {
    try {
        const response = await axios.get("/datasources/ourteam.json");

        return response;
    } catch (error) {
        throw error;
    }
}
import heroIllust from "../../assets/images/hero_illust.jpg";
import OurClients from "./OurClients";
import Portofolios from "./Portofolios";
import WhatWeDo from "./WhatWeDo";
import WhoWeAreSection from "./WhoWeAreSection";

import HomeHero from "./HomeHero";
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {

    return ( 
        <>
            
            <div className="h-screen relative" style={{ backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url(${heroIllust})`}}>
                <HomeHero></HomeHero>
            </div>

            <WhoWeAreSection></WhoWeAreSection>


            
            <div className="py-20 px-5 lg:px-16 2xl:px-56 bg-[#223349]">

                <WhatWeDo></WhatWeDo>


            </div>


            <div className="py-20">
                <OurClients></OurClients>
                

            </div>

            <div className="py-20 px-5 lg:px-16 2xl:px-56">
                <Portofolios></Portofolios>
            </div>



        </>
     );
}
 
export default Home;
import { faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GalleryPicture = (props) => {

    return ( 
        <div className="group relative min-h-[200px] bg-white overflow-hidden"  >
            <div className="transition ease-in-out opacity-0 group-hover:opacity-100 bg-black text-center absolute left-1/2 transform -translate-x-1/2 duration-500 h-full w-full z-[2] shadow-md bg-opacity-80">
                <div className="h-full flex flex-col justify-center gap-2 p-5">
                    <p className="text-2xl font-medium text-[#FFC000] opacity-100">{props.name}</p>
                    <p className="text-lg font-medium text-white">{props.type}</p>
                    <div onClick={props.onImageClick}>
                        <FontAwesomeIcon className="p-2 bg-white rounded-md fa-xl" icon={faMagnifyingGlassPlus} />
                    </div>
                </div>
            </div>
            <div className="absolute top-0 w-full z-[1]">
                <img src={props.image} className="align-middle duration-300 group-hover:scale-110" style={{width:'100%'}} alt={`${props.name}_image`}/>
            </div>
        </div>
     );
}
 
export default GalleryPicture;
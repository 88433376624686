import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import {motion} from "framer-motion";

import rnb from "../../assets/images/product/rnb.png"

const RetailAndB2B = () => {

    const data = [
        "Ready to wear & Novelty made by order",
        "Customize seragam kantor dan acara event",
        "Essential Oils",
        "Minyak Kayuputih"
    ]

    const content = {
        visible: { opacity: 1, transition: { duration: 2, delay:0.5 } },
        hidden: { opacity:0 }
    };

    const [isOpen, setOpen] = useState(false);
    const [ref, inView] = useInView({
      threshold: 0.1
    });

    useEffect(() => {
        if (inView) {
            setOpen(true)
        }
    }, [inView]);

    return ( 
        <>
            <div className="pt-32 pb-0">
                <div className="relative overflow-hidden">
                    <div className="lg:absolute right-0 top-0 h-[300px] lg:h-full w-full lg:w-[40%]">
                        <div className="block lg:absolute bg-cover bg-no-repeat bg-center lg:top-0 lg:right-0 h-full w-full" style={{backgroundImage: `url('${rnb}')`}}></div>
                    </div>
                    <motion.div
                        ref={ref}
                        variants={content}
                        initial="hidden"
                        animate={isOpen ? "visible" : "hidden"} 
                        className="p-10 md:p-20 lg:px-32 xl:px-40 2xl:px-72"
                        >
                            <div className="grid grid-cols-1 lg:grid-cols-2 p-5 xl:py-20 2xl:py-28 text-white">
                                <div>

                                    {/* <motion.p className="text-4xl md:text-5xl text-white font-medium poppins mb-5">Digital and <span className="text-[#FFC000]">Creatives</span> Multimedia<span className="text-[#FFC000]">.</span></motion.p>

                                    <motion.p className="text-white md:text-lg lg:text-base text-justify poppins">Digitalisasi dan perkembangan trend saat ini menuntut pelaku bisnis untuk berkembang lebih pesat, dengan media kreatif yang mudah diterima sesuai perkembangan dunia. GTR Production memberikan layanan digital dan multimedia kreatif sesuai kebutuhan mitra, dengan insight terkini dari sudut pandang trend dan teknologi terkini. Tidak hanya untuk industri besar, melainkan juga untuk UMKM dan industri kecil.</motion.p> */}

                                    <p className="text-5xl md:text-6xl font-medium">Retail and <span className="text-[#FFC000]">B2B</span>.</p>
                     
                                    <div className="flex flex-col gap-2 mt-3 open-sans">
                                        {data.map((text, index) => {
                                                return <div key={`list-${index}`} className="flex items-center gap-3">
                                                    <FontAwesomeIcon icon={faCircle} />
                                                    <p className="text-lg md:text-xl poppins">{text}</p>
                                                </div>
                                        })}
                                    </div>
                                    
                                </div>
                

                            </div>

                    </motion.div>

                </div>
            </div>

        </>
     );
}
 
export default RetailAndB2B;
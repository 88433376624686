import { faBars, faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import gtrLight from "../../../src/assets/images/GTR - logo 2.png"
import gtrBlue from "../../../src/assets/images/GTR - logo 1.png"
import { useAnimate, stagger } from "framer-motion";
import { useEffect } from "react";

const NavbarMobile = ({isScroll, isFixed}) => {

    const [isNavActive, setNavActive] = useState(false);

    const [isDropdownActive, setDropdown] = useState(false);
    
    const mobileNavbar = () => {
        setNavActive(!isNavActive);
    };

    const dropdownToggle = () => {
        setDropdown(!isDropdownActive);
    };


    const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });
    const [scope, animate] = useAnimate();

    useEffect(() => {
        animate(
            '#whatWeDoDowndropMobile',
            isDropdownActive 
            ? {height: 'auto'}
            : {height: 0}
            ,
            {
                type: "spring",
                bounce: 0,
                duration: 0.3,
            }
        );
        animate(
            ".what-we-do-item",
            isDropdownActive
              ? { opacity: 1, zIndex: 1 }
              : { opacity: 0, zIndex: -2 },
            {
              duration: 0.2,
              delay: isDropdownActive ? staggerMenuItems: 0,
              
            }
        );
    }, [isDropdownActive ,animate, staggerMenuItems]);

    return ( 
        <>
             {/* Mobile Navbar */}
             <div className={`${isFixed ? 'fixed': 'sticky'}  lg:hidden top-0 z-10 w-full ${isScroll ? 'bg-white shadow-md': ''} duration-200`}>
                <div className={`flex items-center justify-between ${isScroll ? 'text-black' : 'text-white'}  px-5 py-7`}>

                    <div className='flex items-centerlg:py-0 lg:border-b-0'>
                        <Link to="/">
                            {isScroll ?  <img src={gtrBlue} className="w-20" alt="gtr_logo" /> :  <img src={gtrLight} className="w-20" alt="gtr_logo" />}
                        </Link>
                    </div>
                    <div className=''>
                        <button type='button'  onClick={mobileNavbar}>
                            <FontAwesomeIcon className="h-6 w-6" icon={faBars} />
                        </button>
                    </div>
                </div>
            </div>
            <div id="mobile_nav relative">
                <div id="mob_nav_overlay" className={`${isNavActive ? 'fixed' :'hidden'} lg:hidden w-screen h-screen top-0 z-30 bg-[#0000007d]`}  onClick={mobileNavbar}></div>
                <div className={isNavActive ? `fixed z-50 top-0 left-0 p-8` : `hidden` }  onClick={mobileNavbar}>
                    <i className="fas fa-times fa-2xl text-white"></i>
                </div>
                <div id="mob_nav_body" className={isNavActive ? `fixed bg-white top-0 right-0 shadow-md h-screen lg:hidden lg:justify-between xl:hidden w-full max-w-[80%] duration-300 z-50`: `fixed bg-white top-0 right-0 shadow-md h-screen translate-x-full lg:hidden lg:justify-between xl:hidden w-full max-w-[80%] duration-300 z-50` }>
                    <div className="flex flex-col text-md py-3">
                        <div className='flex justify-between align-middle py-7 px-5 text-end'>
                            <img src={gtrBlue} className="w-20" alt="gtr_logo" /> 
                            <button type='button'  onClick={mobileNavbar}>
                                <FontAwesomeIcon className="h-6 w-6" icon={faClose} />
                            </button>
                        </div>
                        <div ref={scope} className="flex flex-col gap-3 px-5 text-lg text-black poppins">
                            <div>
                                <div className="flex justify-between py-2" onClick={dropdownToggle}>
                                    <p className="text-xl font-medium">What we do</p>
                                    <FontAwesomeIcon className={`h-6 w-6 ${isDropdownActive ? 'rotate-90':''} text-[#FFC000] duration-200`} icon={faChevronRight} />
                                </div>
                                    <div  id="whatWeDoDowndropMobile" className={`grid grid-cols-1 pl-4`}>
                                        <Link onClick={mobileNavbar} to={"digital-and-creative-multimedia"} className="what-we-do-item flex justify-between py-2">
                                            <p className="text-lg font-medium">Digital and Creatives Multimedia</p>
                                        </Link>
                                        <Link onClick={mobileNavbar} to={"trainings"} className="what-we-do-item flex justify-between py-2">
                                            <p className="text-lg font-medium">Trainings</p>
                                        </Link>
                                        <Link onClick={mobileNavbar} to={"consultant-and-event-organizer"} className="what-we-do-item flex justify-between py-2">
                                            <p className="text-lg font-medium">Consultant & Event Organizer</p>
                                        </Link>
                                        <Link onClick={mobileNavbar} to={"retail-and-b2b"} className="what-we-do-item flex justify-between py-2">
                                            <p className="text-lg font-medium">Retail & B2B</p>
                                        </Link>
                                    </div>
                            </div>
                            <Link onClick={mobileNavbar} to={"our-work"} className="flex justify-between py-2">
                                <p className="text-xl font-medium">Our work</p>
                            </Link>
                            <Link onClick={mobileNavbar} to={"/what-we-think"} className="flex justify-between py-2">
                                <p className="text-xl font-medium">What we think</p>
                            </Link>
                            <Link onClick={mobileNavbar} to={"who-we-are"} className="flex justify-between py-2">
                                <p className="text-xl font-medium">Who we are</p>
                            </Link>
                            <a href="https://wa.me/628112274230" target="_blank" rel="noopener noreferrer" className="bg-[#FFC000] px-10 py-3 text-[#FAF6F0] font-medium rounded-full shadow-xl text-center">Contact Us</a>
    
                        </div>
    
                    </div>
                </div>
            </div> 
        </>
     );
}
 
export default NavbarMobile;
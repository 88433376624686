import { Fragment, createRef, useEffect, useRef, useState } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "../components/Footer";
import { useInView } from "react-intersection-observer";
import NavbarFixed from "../components/Navbar/NavbarFixed";
import NavbarSticky from "../components/Navbar/NavbarSticky";


const MainLayout = () => {

    // const [offset, setOffset] = useState(0);

    const [isScrollNav, setScrollNav] = useState(false);

    const [isFixed, setFixed] = useState(true);

    useEffect(() => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if (window.scrollY < 40) {
          setScrollNav(false)
        } else {
          setScrollNav(true)
        }
    }

    


    return ( 
        <Fragment>
        
            {/* <div id='page-container' className='relative min-h-screen bg-gradient-to-bl from-[#1A3159] via-[#121F3B] from-[35%] to-[#0B1322]'> */}
            <div id='page-container' onScroll={handleScroll} className='relative min-h-screen bg-gradient-to-bl from-[#1A3159] via-[#121F3B] from-[35%] to-[#0B1322]'>

                {isFixed ? <NavbarFixed isScroll={isScrollNav}></NavbarFixed> : <NavbarSticky isScroll={isScrollNav}></NavbarSticky>}

                <div id='content-wrap' className=''>
                    <Outlet/>
                </div>
                <footer id='footer' className='bottom-0 w-full'>
                    <Footer />
                </footer>
            </div>
            <ScrollRestoration />

        </Fragment>
     );
}
 
export default MainLayout;
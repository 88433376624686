import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gtrBlue from "../../src/assets/images/GTR - logo 1.png"
import { faFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
    return ( 
        <div className='py-10 px-5  bg-white  lg:px-16 2xl:px-56'>
            <div className="grid grid-cols-1 md:grid-cols-3 mb-5 gap-5">
                <div className="">
                    <img src={gtrBlue} className="w-24 mb-10" alt="gtr_logo" />
                    <div className="w-24">
                        <div className="flex md:flex-col items-center gap-5">
                            <div className="hidden md:block bg-[#FFC000] h-10 w-1"> </div>
                            <a href="https://www.instagram.com/gtrproduction.id/" rel="noreferrer" target="_blank"><FontAwesomeIcon className="p-3 border-2 rounded-full border-[#223349]" icon={faInstagram} /></a>
                            <a href="https://www.facebook.com/people/GTRProductionOfficial/100089802731643/" rel="noreferrer" target="_blank"><FontAwesomeIcon className="p-3 border-2 rounded-full border-[#223349]" icon={faFacebook} /></a>
                            <a href="https://www.tiktok.com/@gtrproduction.official" rel="noreferrer" target="_blank"><FontAwesomeIcon className="p-3 border-2 rounded-full border-[#223349]" icon={faTiktok} /></a>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col gap-5'>
                    <div className="poppins">
                        <p className="font-medium uppercase mb-3 text-[#223349]">What we do</p>
                        <div className="grid">
                            <Link to={"/digital-and-creative-multimedia"} className=" text-[#FFC000] hover:text-[#b19645] mb-1">Digital and Creatives Multimedia</Link>
                            <Link to={"/trainings"} className=" text-[#FFC000] hover:text-[#b19645] mb-1">Training</Link>
                            <Link to={"/consultant-and-event-organizer"} className=" text-[#FFC000] hover:text-[#b19645] mb-1">Consultant & Event Organizer</Link>
                            <Link to={"/retail-and-b2b"} className=" text-[#FFC000] hover:text-[#b19645] mb-1">Retail & B2B</Link>
                        </div>
                    </div>
                    <div>
                        <Link to={"/our-work"} className="font-medium poppins uppercase text-[#223349]">Our Work</Link>
                    </div>
                    <div>
                        <Link to={"/what-we-think"} className="font-medium poppins uppercase text-[#223349]">What we think</Link>
                    </div>
                    <div>
                        <Link to={"/who-we-are"} className="font-medium poppins uppercase text-[#223349]">Who we are</Link>
                    </div>
                </div>

                <div className=''>
                    <div className="poppins">
                        <p className="font-medium poppins uppercase text-[#223349] mb-3">Contact</p>
                        <p className=" mb-3 text-[#FFC000]"> <FontAwesomeIcon icon={faPhone} className="mr-2"></FontAwesomeIcon> +62 811 2274 230</p>
                        {/* <p className=" text-[#FFC000]">PT GTR Production Gemilang Indonesia</p> */}

                        <div className="flex align-middle gap-4">
                            <FontAwesomeIcon className="text-[#FFC000]" icon={faLocationDot}></FontAwesomeIcon>
                            <div>
                            <p className=" text-[#FFC000]">Startup Center IPB</p>
                            <p className="text-sm">Jl. Taman Kencana No.3, Kota Bogor, Jawa Barat 16128</p>
                        <iframe title="maps" className="w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.474669286174!2d106.80149!3d-6.5877681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69c5ccb84430c3%3A0x4b47ca3cd9f4813f!2sJl.%20Taman%20Kencana%20No.3%2C%20RT.03%2FRW.03%2C%20Babakan%2C%20Kecamatan%20Bogor%20Tengah%2C%20Kota%20Bogor%2C%20Jawa%20Barat%2016128!5e0!3m2!1sen!2sid!4v1700671883334!5m2!1sen!2sid" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-center md:items-end gap-3 poppins">
                    <div>

                    </div>
                    <p className="text-sm text-center">Choose What Matters To Your Business & Your Customers</p>
                    <p className="text-sm">@2024 - Copyright</p>
            </div>
        </div>
     );
}
 
export default Footer;
 
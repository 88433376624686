import { useCallback, useEffect, useState } from "react";
import { getPortofolios } from "../../services/Services";
import GalleryPicture from "../../components/GalleryPicture";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import ImageViewer from "../../components/ImageViewer";

const OurWork = () => {
    const [data, setData] = useState([]);
    const [currentImage, setCurrentImage] = useState('');
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((image) => {
        setCurrentImage(image);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

    const title = {
        visible: { opacity: 1, transition: { duration: 1} },
        hidden: { opacity:0 }
    };

    const content = {
        visible: {
            transition: { 
                type: "spring",
                bounce: 0,
                duration: 0.5,
                delay:1,
                delayChildren: 0.2,
                staggerChildren: 0.2 
            } 
        },
        hidden: { transition: {duration: 2, delay:0.5} }
    };
    const itemVariants = {
        visible: {
          opacity: 1,
          y: 0,
          transition: { type: "spring", stiffness: 150, damping: 50 }
        },
        hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } }
      };

    const [isOpen, setOpen] = useState(false);
    const [ref, inView] = useInView({});
    

    async function fetchData(){
        try {
            const result = await getPortofolios();
            const data = result.data.data;
            setData(data);
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }


    useEffect(() => {
        fetchData();

        if (inView) {
            setOpen(true);
        }
    }, [inView]);

    return ( 
        
            <motion.div ref={ref} variants={content} initial={false} animate={isOpen ? "visible":"hidden"} className="pt-36 pb-10 px-5  lg:px-16 2xl:px-56">
                <motion.p variants={title} className="text-5xl font-medium text-white poppins mb-10">Our <span className="text-[#FFC000]">Work</span>.</motion.p>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                    {data.map(({id, name, type, image}, index) => {
                        return <motion.div key={index} variants={itemVariants} ><GalleryPicture  name={name} type={type} image={image} onImageClick={() => openImageViewer(image)}></GalleryPicture></motion.div> 
                    })}
                </div>

                {isViewerOpen && (
                    <ImageViewer 
                        image={currentImage}
                        onClose={closeImageViewer}
                    />
                    //     <ImageViewer

                    //         src={ images }
                    //         currentIndex={ currentImage }
                    //         disableScroll={ false }
                    //         closeOnClickOutside={ true }
                    //         onClose={ closeImageViewer }
                    //         style={{zIndex : 20}}
                    //   />
                    )}
            </motion.div>
     );
}
 
export default OurWork;
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import gtrLight from "../../../src/assets/images/GTR - logo 2.svg"
import gtrBlue from "../../../src/assets/images/GTR - logo 1.svg"
import useMenuDesktopAnimation from "../../hooks/useDesktopMenuAnimation";
import { motion } from "framer-motion";

const NavbarDesktop = ({isScroll}) => {
    const [isShown, setShown] = useState(false);
    // const scope = '';
    const scope = useMenuDesktopAnimation(isShown);
    
    const currentLocation = useLocation();

    return ( 
        <div className={`flex justify-between items-center ${isScroll ? 'text-[#223349]' : 'text-white'} poppins relative`}>
            <Link to={"/"}>
            {/* <img src={gtrBlue} className="w-24" alt="gtr_logo" /> */}
                {isScroll ?  <img src={gtrBlue} className="w-24" alt="gtr_logo" /> :  <img src={gtrLight} className="w-24" alt="gtr_logo" />}
            </Link>
        
            <div className="relative" ref={scope}>
                <div className="flex justify-around gap-10 items-center text-lg font-medium">
                    <motion.div id="what-we-do-button" className="py-2 flex items-center gap-2 border-b-transparent border-b-2 hover:border-[#FFC000]"   onHoverStart={e => {setShown(true)}} onHoverEnd={e => {setShown(false)}} >
                        <p>What we do</p>
                        <FontAwesomeIcon className="text-[#FFC000]" icon={faChevronDown} />

                    </motion.div>    
                    <Link to={"/our-work"} className={`py-2 border-b-2 ${currentLocation.pathname === '/our-work' ? 'border-[#FFC000]' : 'border-b-transparent'} hover:border-[#FFC000]`}>
                        <p>Our work</p>
                    </Link>

                    <Link to={"/what-we-think"} className={`py-2 border-b-2 ${currentLocation.pathname === '/what-we-think' ? 'border-[#FFC000]' : 'border-b-transparent'} hover:border-[#FFC000]`}>
                        <p>What we think</p>
                    </Link>                   
                    <Link to={"who-we-are"} className={`py-2 border-b-2 ${currentLocation.pathname === '/who-we-are' ? 'border-[#FFC000]' : 'border-b-transparent'} hover:border-[#FFC000]`}>
                        <p>Who we are</p>
                    </Link>                   
                </div>

                <motion.div id="whatWeDoDowndrop" className={`absolute w-full z-10 bg-white rounded-lg`} style={{clipPath: "inset(0% 0% 100% 0% round 10px)"}} onHoverStart={e => {setShown(true)}} onHoverEnd={e => {setShown(false)}}>
                    <p className="text-[#223349] px-4 py-3 font-medium text-xl">What we do</p>
                    <ul className="py-2 text-lg font-semibold text-[#223349]">
                        <li>
                            <Link onClick={() => setShown(false)} to={"digital-and-creative-multimedia"} className={`block px-4 py-2 ${currentLocation.pathname === '/digital-and-creative-multimedia' ? 'bg-gray-200' : ''} hover:bg-gray-200`}>Digital and Creatives Multimedia</Link>
                        </li>
                        <li>
                            <Link onClick={() => setShown(false)} to={"/trainings"} className={`block px-4 py-2 ${currentLocation.pathname === '/trainings' ? 'bg-gray-200' : ''} hover:bg-gray-200`}>Training</Link>
                        </li>
                        <li>
                            <Link onClick={() => setShown(false)} to={"/consultant-and-event-organizer"} className={`block px-4 py-2 ${currentLocation.pathname === '/consultant-and-event-organizer' ? 'bg-gray-200' : ''} hover:bg-gray-200`}>Consultant & Event Organizer</Link>
                        </li>
                        <li>
                            <Link onClick={() => setShown(false)} to={"/retail-and-b2b"} className={`block px-4 py-2 ${currentLocation.pathname === '/retail-and-b2b' ? 'bg-gray-200' : ''} hover:bg-gray-200`}>Retail & B2B</Link>
                        </li>
                    </ul>
                </motion.div>{" "}

            </div>
            <a href="https://wa.me/628112274230" target="_blank" rel="noopener noreferrer" className="bg-[#FFC000] hover:bg-[#ffbf00d5] px-10 py-3 text-[#FAF6F0] font-medium rounded-full shadow-xl">Contact Us</a>
        </div>
     );
}
 
export default NavbarDesktop;
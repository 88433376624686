import { useAnimation, motion } from "framer-motion";
import aboutUs from "../../assets/images/about_us.jpg";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";


const WhoWeAreSection = () => {
            
    const content_text = {
        visible: { opacity: 1, x:0, transition: { duration: 1, delayChildren: 0.2, staggerChildren: 0.2  } },
        hidden: { opacity: 0 }
    };

    const itemVariants = {
        visible: {
          opacity: 1,
          y: 0,
          transition: { type: "spring", stiffness: 150, damping: 50 }
        },
        hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } }
      };

    const control = useAnimation();
    const [ref, inView] = useInView({
      threshold: 0.1
    });


    const text_control = useAnimation();
    const [text_ref, textInView] = useInView({
        threshold: 0.5
    });

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }

        if (textInView) {
            text_control.start("visible");
        }
    }, [control, inView, text_control, textInView]);

    return ( 
        <div className="relative overflow-hidden">
            <div className="lg:absolute right-0 top-0 h-[400px] lg:h-full w-full lg:w-[40%]">
                <div className="block lg:absolute bg-cover bg-no-repeat bg-center lg:top-0 lg:right-0 h-full w-full" style={{backgroundImage: `url('${aboutUs}')`}}></div>
            </div>
            <motion.div
                    ref={text_ref}
                    variants={content_text}
                    initial="hidden"
                    animate={text_control}
                    className="p-10 md:p-20 lg:px-32 xl:px-40 2xl:px-72"
                >
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div>

                            <motion.p variants={itemVariants} className="text-4xl md:text-5xl text-white font-medium poppins mb-5">Who We <span className="text-[#FFC000]">Are</span>.</motion.p>

                            <motion.p variants={itemVariants} className="text-white md:text-lg lg:text-base text-justify poppins">GTR Production, startup company dengan spirit generasi muda untuk memajukan industri dan komunitas era digital. Memberikan layanan terbaik, berbasis multimedia, teknologi digital dan trend terkini.</motion.p>
                            <div className="grid grid-cols-1 mt-2">

                                <motion.div variants={itemVariants} className="mb-3">
                                    <p className="text-white text-3xl font-medium mb-3 poppins"><span className="text-[#FFC000]">Vision</span>.</p>
                                    <p className="text-white text-2xl font-medium mb-2 poppins">Globally Competitive Company Through Innovation & Collaboration</p>
                                    <p className="text-white md:text-lg lg:text-base text-justify poppins">Menjadi perusahaan yang berdaya saing global dengan mengedepankan inovasi dan kolaborasi mewujudkan kesejahteraan dan praktik bisnis yang berkelanjutan.</p>
                                </motion.div>
                                <motion.div variants={itemVariants}>
                                    <p className="text-white text-3xl font-medium mb-3 poppins"><span className="text-[#FFC000]">Mission</span>.</p>
                                    <p className="text-white md:text-lg lg:text-base text-justify poppins">Menyediakan produk dan jasa yang berkualitas dengan nilai tambah, serta menjalin kemitraan dengan mitra dan stakeholder.</p>
                                </motion.div>
                            </div>
                        </div>
                        <div></div>

                    </div>

            </motion.div>

        </div>
     );
}
 
export default WhoWeAreSection;
import { useEffect, useState } from "react";
import { getOurClients } from "../../services/Services";

import Marquee from "react-fast-marquee";
const OurClients = () => {

    const [data, setData] = useState([]);

    async function fetchData(){
        try {
            const result = await getOurClients();
            setData(result.data.data);
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    return ( 

        <>
            <p className="text-4xl md:text-5xl font-medium text-center text-white poppins mb-5 md:mb-20">Our <span className="text-[#FFC000]">Client</span>.</p>

            {data ? (
                <Marquee direction="right" className="bg-[#FFC000] py-10">
                    {data.map(({id, name, image}) => {
                        return <div key={`client-${id}`} className="bg-white grid grid-cols-1 place-content-center w-60 h-72 mx-5 p-10 shadow-xl rounded-xl">
                                    <div className="h-48 flex flex-col justify-center">
                                        <img key={`client-${id}`} className="" src={image} alt={name}></img>
                                    </div>
                                    <p className="text-center font-medium poppins">{name}</p>
                                </div>

                        
                    })}
                
                </Marquee>
            ) : (
                <div>
                    <p>Loading</p>
                </div>
            )}
        </>

                
     );
}
 
export default OurClients;
import MainLayout from './layouts/MainLayout';
import Home from './pages/home/Home';
import OurWork from './pages/outwork/OurWork';
import ConsultantEventOrganizer from './pages/whatwedo/ConsultantEventOrganizer';
import DigitalCreativeMedia from './pages/whatwedo/DigitalCreativeMedia';
import RetailAndB2B from './pages/whatwedo/RetailAndB2B';
import Trainings from './pages/whatwedo/Trainings';
import ScrollToTop from './utils/ScrollToTop';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <ScrollToTop>
        <Routes>
            <Route path='/' element={<MainLayout/>}>
                <Route path="" element={<Home/>}></Route>

                <Route path="digital-and-creative-multimedia" element={<DigitalCreativeMedia/>}></Route>
                <Route path="trainings" element={<Trainings/>}></Route>
                <Route path="consultant-and-event-organizer" element={<ConsultantEventOrganizer/>}></Route>
                <Route path="retail-and-b2b" element={<RetailAndB2B/>}></Route>


                <Route path="our-work" element={<OurWork/>}></Route>
            </Route>
            
        </Routes>
    </ScrollToTop>
  );
}

export default App;
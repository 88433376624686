import illust from "../../assets/images/consultant-event-organizer_q8kau5/consultant-event-organizer_q8kau5_c_scale,w_1331.png"
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { stagger, useAnimate } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ConsultantEventOrganizer = () => {

    const data = [
        "Trainings/Pelatihan",
        "Event & Wedding Organizer",
        "Capacity Building & Outbond",
        "Corporate Gathering",
        "Management Consultant",
        "Certification",
        "Pengadaan Barang dan Jasa",
        "Expert Services"
    ]
    
    const [scope, animate] = useAnimate();
    const inView = useInView();

    useEffect(() => {
        const sequence = [
            ["#title_2", {opacity: 1}, {duration: 0.3, delay: 0.2}],
            [".list-business", {opacity: 1, x:0}, {duration: 0.3, delay: stagger(0.1, { startDelay: 0.15 })}]   
        ]

        if (inView) {
            animate("#title_1", {opacity: 1}, {duration: 0.3, delay: 0.2})
            animate(sequence)
        }
    }, [inView]);

    return ( 
        <>
            <div ref={scope} className="pb-10 lg:pb-0">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 min-h-screen lg:min-h-[800px]">
                    <div className="flex justify-center items-center content-center align-middle text-white h-[500px] px-10 lg:h-full bg-cover bg-center" style={{ backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)),url(${illust})`}}>

                        {/* <div id="title_1" className="absolute top-1/2 transform left-1/2 -translate-y-1/2 -translate-x-1/2" > */}
                        <p id="title_1" className="text-5xl md:text-6xl font-medium text-center">Consultant and <span className="text-[#FFC000]">E</span>vent <span className="text-[#FFC000]">O</span>rganizer.</p>

                    </div>
                    <div className="flex flex-col justify-center text-white px-5 py-5 xl:pr-56 poppins">
                        <div id="title_2" style={{opacity: 0}}>
                            <p className="text-4xl md:text-5xl font-medium">What’s Your Business Needs?</p>
                            <p className="text-4xl md:text-5xl font-medium"><span className="text-[#FFC000]">We can provide</span>.</p>                                
                        </div>
                            <div className="flex flex-col gap-2 mt-3 open-sans">
                                {data.map((text, index) => {
                                    return <div key={`list-${index}`} className="list-business flex items-center gap-3" style={{opacity: 0, }}>
                                        <FontAwesomeIcon icon={faCircle} />
                                        <p className="text-lg md:text-xl poppins">{text}</p>
                                    </div>
                                })}
                            </div>
                    </div>
    
                </div>
            </div>
        </>
     );
}
 
export default ConsultantEventOrganizer;
import { useAnimate, stagger } from "framer-motion";
import { useEffect } from "react";

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });

const useMenuDesktopAnimation = (isOpen) => {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        animate(
            '#whatWeDoDowndrop',
            
            {
              clipPath: isOpen
                ? "inset(0% 0% 0% 0% round 10px)"
                : "inset(0% 0% 100% 0% round 10px)"
            },
            {
            
                type: "spring",
                bounce: 0,
                duration: 0.5
            }
        );
        animate(
            "li",
            isOpen
              ? { opacity: 1, scale: 1, x:0}
              : { opacity: 0, scale: 1, x:0 },
            {
              duration: 0.2,
              delay: isOpen ? staggerMenuItems : 0
            }
        );
    }, [isOpen]);

    return scope;
}
 
export default useMenuDesktopAnimation;
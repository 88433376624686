import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const HomeHero = () => {

    
    const boxVariant = {
        visible: { opacity: 1, y:0, transition: { duration: 0.5 } },
        hidden: { opacity: 0, y:40 }
    };

    const control = useAnimation();
    const [ref, inView] = useInView();


    useEffect(() => {
        if (inView) {
          control.start("visible");
        }
      }, [control, inView]);
    return ( 
        
        <motion.div 
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
            // className="absolute top-1/2 transform left-0 right-0">
            className="flex flex-col justify-center px-5 md:px-0 h-full">
                <p className="text-white text-5xl md:text-6xl lg:text-7xl poppins font-medium mb-5 text-center">Suits What You <span className="text-[#FFC000]">Need</span>.</p>
                <p className="text-2xl md:text-3xl text-white poppins text-center">PT GTR Production Gemilang Indonesia</p>
        </motion.div>

     );
}
 
export default HomeHero;
import { useCallback, useEffect, useState } from "react";
import { getPortofolios } from "../../services/Services";
import {useAnimation, motion} from "framer-motion";
import GalleryPicture from "../../components/GalleryPicture";
import {useInView} from "react-intersection-observer";
import ImageViewer from "../../components/ImageViewer";

const Portofolios = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [data, setData] = useState([]);

    
      
    const openImageViewer = useCallback((image) => {
        setCurrentImage(image);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        // setCurrentImage(0);
        setIsViewerOpen(false);
    };
    
    const [images] = useState([]);

    const title = {
        visible: { opacity: 1, transition: { duration: 1} },
        hidden: { opacity:0 }
    };

    const content = {
        visible: {
            transition: { 
                type: "spring",
                bounce: 0,
                duration: 0.5,
                delay:1,
                delayChildren: 0.2,
                staggerChildren: 0.2 
            } 
        },
        hidden: { transition: {duration: 2, delay:0.5} }
    };
    const itemVariants = {
        visible: {
          opacity: 1,
          y: 0,
          transition: { type: "spring", stiffness: 150, damping: 50 }
        },
        hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } }
      };
            
    const control = useAnimation();
    const [ref, inView] = useInView({
      threshold: 0.1
    });

    useEffect(() => {
        fetchData();
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);

    async function fetchData(){
        try {
            const result = await getPortofolios();
            const data = result.data.data.slice(0, 6);
        
            setData(data);
            data.forEach(element => {
                images.push(element.image);
            });

        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }

    return ( 
            <motion.div 
            ref={ref}
            variants={content}
            initial="hidden"
            animate={control}>

                <motion.p variants={title} className="text-4xl md:text-5xl font-medium text-center text-white poppins mb-5 md:mb-20">Our <span className="text-[#FFC000]">Work</span>.</motion.p>

                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
                    {data.map(({id, name, type, image}, index) => {
                        return <motion.div variants={itemVariants} key={index}><GalleryPicture name={name} type={type} image={image} onImageClick={() => openImageViewer(image)}></GalleryPicture></motion.div> 
                    })}
                </div>


                {isViewerOpen && (
                    <ImageViewer 
                        image={currentImage}
                        onClose={closeImageViewer}
                    />
                )}
            </motion.div>
     );
}
 
export default Portofolios;
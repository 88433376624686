import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

const NavbarSticky = ({isScroll}) => {
    return ( 
        <>
            <div id="desktop-navbar" className={`hidden lg:block md:sticky top-0 left-0 right-0 py-8 xl:px-56  ${isScroll ? 'bg-white shadow-xl': 'bg-transparent'} z-10 duration-200`}>
                <NavbarDesktop isScroll={isScroll}></NavbarDesktop>
                
            </div>    

            <NavbarMobile isScroll={isScroll} isFixed={false}></NavbarMobile>

                  
        </>
     );
}
 
export default NavbarSticky;
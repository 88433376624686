import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';

import Home from './pages/home/Home';
import DigitalCreativeMedia from './pages/whatwedo/DigitalCreativeMedia';
import Trainings from './pages/whatwedo/Trainings';
import ConsultantEventOrganizer from './pages/whatwedo/ConsultantEventOrganizer';
import RetailAndB2B from './pages/whatwedo/RetailAndB2B';
import OurWork from './pages/outwork/OurWork';
import WhoWeAre from './pages/whoweare/WhoWeAre';
import MainLayout from './layouts/MainLayout';
import WhatWeThink from './pages/whatwethink/WhatWeThink';


const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path:'/',
        element: <Home />
      },
      {
        path:'consultant-and-event-organizer',
        element: <ConsultantEventOrganizer/>
      },
      {
        path:'retail-and-b2b',
        element: <RetailAndB2B/>
      },
      {
        path:'digital-and-creative-multimedia',
        element: <DigitalCreativeMedia></DigitalCreativeMedia>
      },
      {
        path:'trainings',
        element: <Trainings />
      },
      {
        path:'our-work',
        element: <OurWork />
      },
      {
        path:'who-we-are',
        element: <WhoWeAre />
      },
      {
        path:'what-we-think',
        element: <WhatWeThink />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useInView } from "react-intersection-observer";
import WhatWeDoCard from "../../components/WhatWeDoCard";
import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonChalkboard, faShirt } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays, faLightbulb } from "@fortawesome/free-regular-svg-icons";


const WhatWeDo = () => {

    const data = [
        {
            title: "Consultant & Event Organizer",
            desc: "Trainings, Event & Wedding Organizer, Management Consultant, Certification, Environmental Consultant, Landscaping, pengadaan barang dan jasa, pertanian dalam arti luas.",
            icon: <FontAwesomeIcon className="fa-xl" icon={faCalendarDays} />
        },
        {
            title: "Digital and Creatives Multimedia",
            desc: "Digital dan Creative Multimedia, untuk industri dan perusahaan, baik skala besar maupun UMKM.",
            icon: <FontAwesomeIcon className="fa-xl" icon={faLightbulb} />
        },
        {
            title: "Training",
            desc: "Pelatihan SDM, Pendampingan, Monitoring.",
            icon: <FontAwesomeIcon className="fa-xl" icon={faPersonChalkboard} />
        },
        {
            title: "Retail & B2B",
            desc: "Essential Oils, Fashion and Ready to Wear.",
            icon: <FontAwesomeIcon className="fa-xl" icon={faShirt} />
        }
    ]

    const title = {
        visible: { opacity: 1, transition: { duration: 2 } },
        hidden: { opacity:0 }
    };

    const content = {
        visible: {
            transition: { 
                type: "spring",
                bounce: 0,
                duration: 0.5,
                delay:1,
                delayChildren: 0.2,
                staggerChildren: 0.2 
            } 
        },
        hidden: { transition: {duration: 2, delay:0.5} }
    };
    const itemVariants = {
        visible: {
          opacity: 1,
          y: 0,
          transition: { type: "spring", stiffness: 150, damping: 50 }
        },
        hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } }
      };

    const control = useAnimation();
    const [ref, inView] = useInView({
      threshold: 0.5
    });

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    
    return ( 

        <motion.div ref={ref}  initial="hidden" variants={content} animate={control} className="">
            <motion.p className="text-4xl md:text-5xl font-medium text-center text-white poppins mb-10" variants={title}>
                What <span className="text-[#FFC000]">We</span> Do<span className="text-[#FFC000]">.</span>
            </motion.p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                { data ? (
                    data.map(({title, desc, icon}, index) => {
                        return <motion.div key={`what-we-do-${index}`}  variants={itemVariants} className="mb-4"><WhatWeDoCard title={title} desc={desc} icon={icon}></WhatWeDoCard></motion.div> 

                    })
                ) : (
                    <p>Loading</p>
                )}
            </div>
        </motion.div>
     );
}
 
export default WhatWeDo;
import illust from "../../assets/images/training.jpg"
import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Trainings = () => {

    const content = {
        visible: { opacity: 1, transition: { duration: 2, delay:0.3 } },
        hidden: { opacity:0 }
    };

    const control = useAnimation();
    const [ref, inView] = useInView({
      threshold: 0.1
    });
    
    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return ( 
        <>
            <div className="pt-32 pb-0">
                <div className="relative overflow-hidden">
                    <div className="lg:absolute left-0 top-0 h-[300px] lg:h-full w-full lg:w-[40%]">
                        <div className="block lg:absolute bg-cover bg-no-repeat bg-center lg:top-0 lg:right-0 h-full w-full" style={{backgroundImage: `url('${illust}')`}}></div>
                    </div>
                    <motion.div
                        ref={ref}
                        variants={content}
                        initial="hidden"
                        animate={control}
                        className="lg:px-32 xl:px-40 2xl:px-72"
                        >
                            <div className="grid grid-cols-1 lg:grid-cols-2 p-5 xl:py-20 2xl:py-28">
                                <div className="col-start-2">
                                    <motion.p className="text-4xl md:text-5xl text-white font-medium poppins mb-5">Train<span className="text-[#FFC000]">ings</span></motion.p>
                                
                                    <motion.p className="text-white md:text-lg lg:text-base text-justify poppins">Training atau pelatihan yang diberikan sepenuhnya disesuaikan oleh kebutuhan dan penilaian awal sehingga materi yang diberikan terfokus dan memiliki dampak jangka pendek maupun panjang. Selain itu, program pendampingan dapat diberikan untuk mendapatkan hasil yang lebih baik, mencakup sesi paparan materi sampai dengan monitoring penilaian keberhasilan program.</motion.p>

                                </div>
                

                            </div>

                    </motion.div>

                </div>
            </div>
        </>
     );
}
 
export default Trainings;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const ImageViewer = (props) => {

    
    return ( 
        <>
            <div className="fixed top-0 h-screen w-full z-30  inset-0">
                <div className="absolute h-full w-full bg-black bg-opacity-70"  onClick={props.onClose}>
                </div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <p className="text-end text-white fa-xl mb-3" onClick={props.onClose}><FontAwesomeIcon icon={faClose}/></p>
                    <img src={props.image} alt="" />
                </div>
            </div>
        </>
     );
}
 
export default ImageViewer;